<template>
  <div
    class="row"
    v-if="
      userRole < 2 && currentOrg.currentPlan === teamPlan && userStatus === 2
    "
  >
    <div class="col-lg-12 col-md-12">
      <div class="card">
        <div class="card-body">
          <preset-table
            :presets="currentPresets"
            :currentOrg="currentOrg"
            @editPreset="editPreset"
            @deletePreset="deletePreset"
            @setAsDefault="setAsDefault"
            @addNewPreset="addNewPreset"
            @enablePreset="enablePreset"
          />
        </div>
      </div>
    </div>
  </div>
  <div
    v-else-if="
      userRole < 2 && currentOrg.currentPlan === teamPlan && userStatus < 2
    "
  >
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="card-body">
            <h5>
              Sorry, it seems like you do not have access to this page.
              {{ userStatus }}
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="card-body">
            <h5>Please upgrade to be able to edit and manage Team Presets.</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PresetForm from "@/components/Preset/PresetForm";
import PresetTable from "@/components/Preset/PresetTable";
import AButton from "@/components/Button";
import { useStore } from "vuex";
import { computed, reactive, ref, watch } from "@vue/runtime-core";
import { useRouter } from "vue-router";

export default {
  name: "VbPresets",
  components: {
    PresetForm,
    PresetTable,
    AButton,
  },
  setup() {
    const store = useStore();
    const user = computed(() => store.getters["user/user"]);
    const currentOrg = reactive({});
    const currentPresets = reactive([]);
    const router = useRouter();
    const teamPlan = "BWzAZl9E";
    const userStatus = ref(0);
    const userRole = ref(2);

    Object.assign(currentOrg, user.value.currentOrg);
    Object.assign(currentPresets, user.value.currentOrg.presets);
    const match = user.value.currentOrg.users.find((usr) => {
      return usr.id === user.value.id;
    });

    if (match) {
      userStatus.value = match.status;
      userRole.value = match.role;
    }

    watch(user.value, () => {
      currentPresets.splice(0);
      Object.assign(currentOrg, user.value.currentOrg);
      Object.assign(currentPresets, currentOrg.presets);
      const match = user.value.currentOrg.users.find((usr) => {
        return usr.id === user.value.id;
      });

      if (match) {
        userStatus.value = match.status;
        userRole.value = match.role;
      }
    });

    return {
      store,
      currentOrg,
      currentPresets,
      teamPlan,
      router,
      userStatus,
      userRole,
    };
  },
  mounted() {
    const orgId = this.currentOrg.id;
    watch(this.currentOrg, () => {
      if (orgId !== this.currentOrg.id) {
      }
    });
  },
  methods: {
    editPreset(presetId) {
      this.router.push({
        name: "PresetDetail",
        params: { id: presetId },
      });
    },
    deletePreset(presetId) {
      this.store.dispatch("user/DELETE_PRESET", {
        orgId: this.currentOrg.id,
        presetId: presetId,
      });
    },
    setAsDefault(presetId) {
      this.store.dispatch("user/SET_DEFAULT_PRESET", {
        orgId: this.currentOrg.id,
        presetId: presetId,
      });
    },
    addNewPreset() {
      this.router.push({
        name: "PresetDetail",
        params: { id: "new" },
      });
    },
    enablePreset(preset) {
      this.store.dispatch("user/ENABLE_PRESET", {
        orgId: this.currentOrg.id,
        presetId: preset.id,
        isActive: preset.isActive,
      });
    },
  },
};
</script>
