<template>
  <div class="row">
    <button
      class="btn btn-primary px-2 right-button"
      type="submit"
      @click="addNewPreset"
    >
      Add a preset
    </button>
  </div>
  <div class="row">
    <div class="col-lg-12 col-md-12">
      <h5>
        You have enabled {{ activePresetCount }} of your
        {{ presetLimit }} presets.
      </h5>
    </div>
  </div>
  <div class="table-responsive text-nowrap">
    <a-table :columns="columns" :data-source="presets">
      <template #name="{ text }">
        <span v-if="searchText">
          <template
            v-for="(fragment, i) in text.name
              .toString()
              .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
          >
            <mark
              v-if="fragment.toLowerCase() === searchText.toLowerCase()"
              :key="i"
              class="highlight"
              >{{ fragment }}</mark
            >
            <template v-else>{{ fragment }}</template>
          </template>
        </span>
        <template v-else>
          <a
            class="btn btn-sm btn-light"
            href="javascript: void(0);"
            @click="editPreset(text.id)"
            >{{ text.name }}</a
          >
        </template>
      </template>
      <template #action="{ text }">
        <span>
          <a
            href="javascript: void(0);"
            class="btn btn-sm btn-light mr-2"
            @click="editPreset(text.id)"
          >
            <i class="fe fe-edit mr-2" />
            Edit
          </a>
          <a
            href="javascript: void(0);"
            class="btn btn-sm"
            @click="text.isDefaultPreset ? '' : showConfirm(text)"
            :class="text.isDefaultPreset ? 'btn-disabled' : 'btn-danger'"
          >
            <small>
              <i class="fe fe-trash mr-2" />
            </small>
            Delete
          </a>
        </span>
      </template>
      <template #default="{ text }">
        <span v-if="text.isDefaultPreset">DEFAULT</span>
        <span v-else>
          <a
            href="javascript: void(0);"
            class="btn btn-sm btn-light"
            @click="setAsDefault(text.id)"
          >
            <small>
              <i class="fe fe-star mr-2" />
            </small>
            Make Default
          </a>
        </span>
      </template>
      <template #status="{ text }">
        <span>
          <a-switch
            :checked="text.isActive"
            @change="
              text.isDefaultPreset
                ? popUpError('You cannot change status of the default preset!')
                : enablePreset(text.id, text.isActive)
            "
          />
        </span>
      </template>
    </a-table>
  </div>
</template>

<script>
import { reactive, ref, watch } from "vue";
const columns = [
  {
    title: "Name",
    key: "name",
    sorter: (a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    },
    slots: {
      filterDropdown: "filterDropdown",
      filterIcon: "filterIcon",
      customRender: "name",
    },
    onFilter: (value, record) =>
      record.customer.toLowerCase().includes(value.toLowerCase()),
  },
  {
    title: "Action",
    slots: { customRender: "action" },
  },
  {
    title: "Default",
    slots: { customRender: "default" },
  },
  {
    title: "Active",
    slots: { customRender: "status" },
  },
];

export default {
  props: {
    presets: [Object],
    currentOrg: {},
  },
  emits: [
    "editPreset",
    "deletePreset",
    "setAsDefault",
    "addNewPreset",
    "enablePreset",
  ],
  setup(props) {
    const searchText = ref("");
    const searchInput = ref(null);
    const presets = reactive([]);
    const presetLimit = props.currentOrg.numberOfPresets;
    const activePresetCount = ref(0);

    Object.assign(presets, props.presets);
    activePresetCount.value = props.currentOrg.presets.filter(
      (preset) => preset.isActive
    ).length;

    watch(props.presets, () => {
      presets.splice(0);
      Object.assign(presets, props.presets);
      activePresetCount.value = props.currentOrg.presets.filter(
        (preset) => preset.isActive
      ).length;
    });

    const handleSearch = (selectedKeys, confirm) => {
      confirm();
      searchText.value = selectedKeys[0];
    };

    const handleReset = (clearFilters) => {
      clearFilters();
      searchText.value = "";
    };

    return {
      searchText,
      searchInput,
      columns,
      handleReset,
      handleSearch,
      presets,
      activePresetCount,
      presetLimit,
    };
  },
  methods: {
    showConfirm(preset) {
      const that = this;
      this.$confirm({
        title: "Delete preset",
        content: 'Do you want to delete preset "' + preset.name + '" ?',
        onOk() {
          that.$emit("deletePreset", preset.id);
        },
        onCancel() {},
      });
    },
    editPreset(key) {
      this.$emit("editPreset", key);
    },
    deletePreset(key) {
      this.$emit("deletePreset", key);
    },
    setAsDefault(key) {
      this.$emit("setAsDefault", key);
    },
    addNewPreset() {
      if (this.activePresetCount < this.presetLimit) {
        this.$emit("addNewPreset");
      } else {
        this.$confirm({
          title: "Oops! Maximum Number of Presets Reached",
          content:
            "You have reached the maximum number of presets. Please upgrade or disable other presets to enable this preset?",
          onOk() {},
          onCancel() {},
        });
      }
    },
    enablePreset(id, isActive) {
      const preset = {
        id: id,
        isActive: !isActive,
      };
      if (!isActive) {
        if (this.activePresetCount < this.presetLimit) {
          this.presets.find((preset) => preset.id === id).isActive = true;
          this.$emit("enablePreset", preset);
        } else {
          this.presets.find((preset) => preset.id === id).isActive = false;
          this.$confirm({
            title: "Oops! Maximum Number of Presets Reached",
            content:
              "You have reached the maximum number of presets. Please upgrade or disable other presets to enable this preset?",
            onOk() {},
            onCancel() {},
          });
        }
      } else {
        this.presets.find((preset) => preset.id === id).isActive = false;
        this.$emit("enablePreset", preset);
      }
    },
    popUpError(error) {
      this.$error({
        title: "Oops! Please check",
        content: error,
      });
    },
  },
};
</script>

<style scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}

.right-button {
  margin-top: auto;
  margin-bottom: 20px;
  margin-right: 15px;
}
</style>
